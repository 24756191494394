import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Home } from './pages/home/home'
import Store from './pages/store/store'
import ComingSoon from './pages/ComingSoon/comingSoon'

function App(): JSX.Element {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/store' element={<Store />} />
          <Route path='/comingSoon' element={<ComingSoon />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
