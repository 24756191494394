import { Link, redirect } from 'react-router-dom'
import { CsHeader } from '../../components/header/csHeader'
import Title from '../../components/title/title'
import { Footer } from '../../components/footer/footer'
import '../../App.css'

import headerImage from '../../assets/images/ComingSoonBanner.png'

function comingSoon() {
  const title = ''
  const subtitle = ''

  return (
    <div>
      <div className='w-full overflow-hidden relative storeHeader'>
        <CsHeader showVideo={false} headerImage={headerImage}>
          <img src='' alt='Header Image' className='h-full w-full object-cover storeHeaderImage' />
        </CsHeader>
        <Title title={title} subtitle={subtitle}></Title>
      </div>

      <div className='goBack'>
        <Link to='/'>
          <button className='bg-[#A100FF] hover:bg-white hover:text-[#A100FF] text-white font-bold px-3 pb-1 pt-0.5 goBack inline-flex items-center m-8'>
            <span className='text-5xl mb-2'>&larr;</span>
            &nbsp;Go back to Homepage
          </button>
        </Link>
      </div>

      <Footer />
    </div>
  )
}

export default comingSoon
