import React from 'react'
import Island01 from '../../../assets/images/Island01.jpg'
import '../../../App.css'

type MissionProps = {
  ID: string
}

export const Mission = ({ ID }: MissionProps) => {
  return (
    <div id={ID} className='flex flex-col w-full py-16 relative text-white'>
      <div className='grid grid-cols-1 mssn:grid-cols-2 gap-4'>
        <div>
          <div className='mssnColOne grid pl-4 xl:pl-32 pr-4 xl:pr-16 xl:px-32 relative gap-4 text-left mx-auto'>
            <h2 className='text-[4.77vw] smmd:text-[2.77vw] font-big-title items-center'>
              Create your virtual events quickly with templates
            </h2>
            <p className='text-[3.5vw] sm:text-base xl:text-xl break-words'>
              Build your own experience faster by using any of the 37 world templates available for
              you.
            </p>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-3 mssn:mb-16 text-center'>
              <div className='col-span-1 items-center pb-4'>
                <h3 className='py-3 text-[6vw] sm:text-2xl'>100%</h3>
                <p className='text:[10vw] xl:text-base'>Customizable</p>
              </div>
              <div className='col-span-1 items-center pb-4'>
                <h3 className='py-3 text-[6vw] sm:text-2xl'>+3000</h3>
                <p className='xl:text-base'>Assets</p>
              </div>
              <div className='col-span-1 items-center pb-4'>
                <p className='infinite items-center sm:text-2xl'>&infin;</p>
                <p className='xl:text-base'>Possibilities</p>
              </div>
            </div>
          </div>
          {/* Available button if needed */}
          {/*  <div className='absolute left-0 flex bottom-16 text-xs mx-16 xl:mx-32 gap-4'>
            <a
              href='https://kxdocuments.accenture.com/Contribution/fd142070-8560-4053-a9a8-049c27051f49'
              target='_blank'
              className='py-2 px-8 text-white text-base'
            >
              View Demo Catalogue
            </a>
          </div> */}
        </div>
        <div className='justify-self-end'>
          <div className='mssnColTwo inline-flex justify-items-end relative mx-4 xl:mr-32'>
            <img src={Island01} alt='Island Demo 1' />
          </div>
        </div>
      </div>
    </div>
  )
}
