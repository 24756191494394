import React, { useRef, useEffect } from 'react'
import videoUrl from '../../assets/Header_Animation_Dark_Colors.mp4'
export const HeaderVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoStyle = {
    zIndex: '-999',
    width: '100%',
    height: 'auto',
  }
  useEffect(() => {
    if (videoRef.current) {
      //videoRef.current.currentTime = 0  establece el tiempo actual a 60 segundos
    }
  }, [])
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime
      if (currentTime >= 18) {
        videoRef.current.currentTime = 10.5
      } // reinicia el video después de 120 segundos (2 minutos)
    }
  }
  return (
    <video
      src={videoUrl}
      autoPlay
      muted
      loop
      onTimeUpdate={handleTimeUpdate} // escucha los cambios en el tiempo de reproducción
      style={videoStyle}
      className=''
      data-testid='video-test'
      playsInline
      controls={false}
      disablePictureInPicture
      disableRemotePlayback
      preload='auto'
      poster=''
      ref={videoRef}
    >
      Your browser does not support the video tag.
    </video>
  )
}
