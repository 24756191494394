import React from 'react'
import '../../../App.css'

type SolutionProps = {
  ID: string
}

export const Solution = ({ ID }: SolutionProps) => {
  return (
    <div className='bg-center bg-cover flex flex-col gap-8 items-center p-8' id={ID}>
      <p className='mx-auto text-center text-white font-bold text-[4vw] mssn:text-[2.77vw]'>
        Start creating your worlds on VSF now
      </p>
      <div className='externalLinks flex gap-8 mx-auto'>
        <a
          href='mailto:VSF_Helpdesk@accenture.com'
          target='_blank'
          className='py-2 px-8 text-white hover:bg-white hover:text-[#A100FF] font-bold text-xs lg:text-sm xl:text-base bg-transparent border border-white'
        >
          Contact Us
        </a>
      </div>
    </div>
  )
}
