import React from 'react'
import img1 from '../../../assets/images/div1.png'
import img2 from '../../../assets/images/div2.png'
import img3 from '../../../assets/images/div3.png'
import img4 from '../../../assets/images/div4.png'
import img5 from '../../../assets/images/div5.png'
import img6 from '../../../assets/images/div6.png'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Import the carousel styles
import '../../../App.css' // Import the override styles

const SimpleSlider = () => {
  const onChange = (index: number) => {
    console.log(`Slide changed to index ${index}`)
  }

  const onClickItem = (index: number) => {
    console.log(`Clicked on slide with index ${index}`)
  }

  const onClickThumb = (index: number) => {
    console.log(`Clicked on thumbnail with index ${index}`)
  }

  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      onChange={onChange}
      onClickItem={onClickItem}
      onClickThumb={onClickThumb}
    >
      <div>
        <img src={img1} alt='Slide 1' />
        <div className='legend col-span-1 items-start xl:items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              A&D Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              The A&D space features interactive environments with multimedia displays, large-scale
              models, and information boards where users can engage in conversations and shared
              activities.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              Hundreds of users can simultaneously tour and interact with each other around the
              space.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=A%26D_Demo'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <img src={img2} alt='Slide 2' />
        <div className='legend col-span-1 items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              Dock Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              This stylish virtual lounge features a presentation stage, a coffee bar, and several
              social areas to interact and play games with other users.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              Dozens of users can share real-time conversations, watch audiovisual content, play
              video games together, and spectate from business presentations to music shows.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=DockDemo'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <img src={img3} alt='Slide 3' />
        <div className='legend col-span-1 items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              Recruit World Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              A versatile space with several areas ideal for simultaneous presentations, meetings,
              or expo stands. Users can attend different activities and share in social spaces.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              Hundreds of users can freely explore the different sections of the space, interact
              with multimedia, enjoy the video game zones, interact with other users, and attend any
              of the simultaneous presentations.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=RecruitworldDemo'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <img src={img4} alt='Slide 4' />
        <div className='legend col-span-1 items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              Gallery Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              An exhibition room with an open space concept that allows close interactions with art
              pieces and multimedia elements.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              A few dozen users can enjoy art and music, share in real-time, enjoy audiovisual
              content, or play video games together.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=GalleryDemo'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <img src={img5} alt='Slide 5' />
        <div className='legend col-span-1 items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              Retail World Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              This space has multiple virtual stands to perform exclusive retail engagements, such
              as virtual stands, brand collaborations, digital asset promotions, and virtual product
              demos where users can interact with guest brand ambassadors.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              Exclusive access to small groups of VIP users. Simultaneous user and product
              interactions are possible in every area of this space.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=RetailWorldDemo'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <img src={img6} alt='Slide 6' />
        <div className='legend col-span-1 items-center gap-base'>
          <div className='legendText row-span-1 grid gap-0.5 2xl:gap-4'>
            <h2 className='row-span-1 text-[1.2rem] md:text-[2vw] lg:text-xl xl:text-2xl'>
              Ocean Race Demo
            </h2>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              The Ocean Race is the toughest race on earth, and the Virtual Regatta is the first
              sailing race simulator to meet for the first time ever in the metaverse.
            </p>
            <p className='row-span-1 text-[1vw] lg:text-[1.1vw] xl:text-[0.8vw]'>
              In this event, the participants will be able to follow the teams, learn what it takes,
              get inside the action and compete for the sailing trophy joining thousands of fans.
            </p>
            <a
              className='py-2 px-8 text-white font-bold text-base bg-transparent border border-white'
              href='https://sandbox.virtualshowfloor.cloud/auth/login?event=theoceanrace2023'
              target='_blank'
            >
              Go to Demo
            </a>
          </div>
        </div>
      </div>
    </Carousel>
  )
}

export default SimpleSlider
