// FilterButton.js
import React from 'react'

interface FilterButtonProps {
  tag: string
  isActive: boolean
  onClick: (tag: string) => void
}

const FilterButton: React.FC<FilterButtonProps> = ({ tag, isActive, onClick }) => {
  return (
    <button
      className={`px-4 py-2 ${isActive ? 'bg-[#A100FF] text-white' : 'bg-[#e6e6dc]'}`}
      onClick={() => onClick(tag.toLowerCase())}
    >
      {tag.charAt(0).toUpperCase() + tag.slice(1)}
    </button>
  )
}

export default FilterButton
