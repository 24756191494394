import React from 'react'
import '../../../App.css'

type ColumnContentProps = {
  image: string
  title: string
  description: string
}

const ColumnContent = ({ image, title, description }: ColumnContentProps) => {
  return (
    <div className='benefitColumn col-span-1 flex flex-col items-center gap-2 sm:gap-6 px-6 py-4 sm:py-20'>
      <img src={image} alt='' />
      <h2 className='text-[1.4rem] smmd:text-[2.8vw] mssn:text-[2vw] 2xl:text-[1.77vw] font-big-title break-words pre-line'>
        {title}
      </h2>
      <p className='text-[3.5vw] sm:text-base xl:text-xl break-words'>{description}</p>
    </div>
  )
}

export default ColumnContent
