import React from 'react'
import { Mission } from './mission/mission'
import { Vision } from './vision/vision'
import { Solution } from './solution/solution'
import { Footer } from '../footer/footer'
import { Catalogue } from './catalogue/catalogue'

export const Sections = () => {
  return (
    <div className='bg-black flex flex-col'>
      <Catalogue ID='catalogue' />
      <Vision ID='vision' />
      <Mission ID='mission' />
      <Solution ID='solution' />
      <Footer />
    </div>
  )
}
