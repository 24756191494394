import React, { useEffect, useState, useCallback, useMemo } from 'react'
import FilterButton from './FilterButton'
import '../../../index.css'
import '../../../App.css'
import Config from '../../../config.json'
// For local publishing
// import accessToken from './accessToken'

// For online publishing
const accessToken = Config.VSF_K8S_SKETCHFAB_TOKEN

interface Model {
  uid: string
  name: string
  embedUrl: string
  orgTags: string[]
}

interface SketchfabAPIProps {
  id: string
}

export const SketchfabAPI: React.FC<SketchfabAPIProps> = ({ id }) => {
  const [models, setModels] = useState<Model[]>([])
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null)
  const [prevPageUrl, setPrevPageUrl] = useState<string | null>(null)
  const [comingSoon, setComingSoon] = useState<boolean>(false)

  useEffect(() => {
    console.log('Access token: ', accessToken)
  }, [])

  const fetchModels = async (url: string) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })

      if (response.ok) {
        const contentType = response.headers.get('content-type')

        if (contentType && contentType.includes('application/json')) {
          const data = await response.json()

          setNextPageUrl(data.next)
          setPrevPageUrl(data.previous)

          // Find the longest name
          let longestNameLength = 0
          data.results.forEach((model: Model) => {
            const formattedName = model.name.replace(/(_|_\s)/g, ' ')
            if (formattedName.length > longestNameLength) {
              longestNameLength = formattedName.length
            }
          })

          setModels(
            data.results.map((model: Model) => ({
              ...model,
              name: model.name.replace(/(_|_\s)/g, ' ').padEnd(longestNameLength, ' '),
            }))
          )

          console.log(data)
        } else {
          throw new Error('Failed to fetch models')
        }
      } else {
        throw new Error('Failed to fetch models')
      }
    } catch (error) {
      console.error('Error fetching models:', error)
    }
  }

  useEffect(() => {
    console.log('Active Filters:', activeFilters)
    let filterQuery = ''
    if (
      activeFilters.includes('male') ||
      activeFilters.includes('female') ||
      activeFilters.includes('kids') ||
      activeFilters.includes('nonbinary') ||
      activeFilters.includes('boy') ||
      activeFilters.includes('girl')
    ) {
      filterQuery = activeFilters.filter((filter) => filter != 'avatar').join('&orgTags=')
    } else if (
      activeFilters.includes('screens') ||
      activeFilters.includes('plants') ||
      activeFilters.includes('helpers')
    ) {
      filterQuery = activeFilters.filter((filter) => filter != 'props').join('&orgTags=')
    } else {
      filterQuery = activeFilters.join('&orgTags=')
    }
    const url = `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd${
      filterQuery ? `&orgTags=${filterQuery}` : ''
    }&count=15`
    fetchModels(url)
    console.log('URL:', url)
    console.log('Filtered Models: ', filteredModels)
  }, [activeFilters, page])

  const handleToggleFilter = (tag: string) => {
    tag = tag.toLowerCase()

    if (tag === 'avatar') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter(
            (filter) =>
              filter !== tag &&
              filter !== 'male' &&
              filter !== 'female' &&
              filter !== 'nonbinary' &&
              filter !== 'kids' &&
              filter !== 'boy' &&
              filter !== 'girl'
          )
        } else {
          return [...prevFilters, tag]
        }
      })
    } else if (tag === 'male') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('male')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=male&count=15`
        )
      }
    } else if (tag === 'female') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('female')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=female&count=15`
        )
      }
    } else if (tag === 'nonbinary') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('nonbinary')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=nonbinary&count=15`
        )
      }
    } else if (tag === 'kids') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter(
            (filter) => filter !== tag && filter !== 'boy' && filter !== 'girl'
          )
        } else {
          return [...prevFilters, tag, 'boy', 'girl']
        }
      })

      if (!activeFilters.includes('kids')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=kids&count=15`
        )
      }
    } else if (tag === 'props') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter(
            (filter) =>
              filter !== tag && filter !== 'screens' && filter !== 'plants' && filter !== 'helpers'
          )
        } else {
          return [...prevFilters, tag]
        }
      })
    } else if (tag === 'screens') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('screens')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=screens&count=15`
        )
      }
    } else if (tag === 'plants') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('plants')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=plants&count=15`
        )
      }
    } else if (tag === 'helpers') {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })

      if (!activeFilters.includes('helpers')) {
        fetchModels(
          `https://api.sketchfab.com/v3/orgs/@Accenture/models?projects=f0a77b1868f047f197091aa99573ecbd&orgTags=helpers&count=15`
        )
      }
    } else {
      setActiveFilters((prevFilters) => {
        if (prevFilters.includes(tag)) {
          return prevFilters.filter((filter) => filter !== tag)
        } else {
          return [...prevFilters, tag]
        }
      })
    }
  }

  const filteredModels = useMemo(() => {
    if (activeFilters.length === 0) {
      return models.slice(0, 15)
    }

    if (activeFilters.includes('male')) {
      return models.filter((model) => model.orgTags.includes('male'))
    }

    return models.filter((model) => model.orgTags.some((tag) => activeFilters.includes(tag)))
  }, [models, activeFilters])

  const sendEmail = (model: Model) => {
    const subject = `Request for info - ${model.name}`
    const body = `Hi, \n\nI'm interested in getting more information about ${model.name}.\n\nThanks!`
    const mailtoLink = `mailto:VSF_Helpdesk@accenture.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  const handleLoadMore = () => {
    if (nextPageUrl) {
      fetchModels(nextPageUrl)
      console.log('Next Page URL:', nextPageUrl)
    }
  }

  const handleLoadPrevious = () => {
    if (prevPageUrl) {
      fetchModels(prevPageUrl)
      console.log('Previous Page URL:', prevPageUrl)
    }
  }

  return (
    <div id={id} className='w-full pt-8 mb-8flex flex-col items-center bg-white'>
      {comingSoon ? (
        <p className='text-[5vw] text-white mt-8'>Coming soon...</p>
      ) : (
        <div>
          <h2 className='text-2xl font-bold mb-4'>Assets</h2>
          <div className='mb-4 grid justify-items-center grid-cols-1'>
            <div className='primaryFilters grid grid-row grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 justify-center'>
              {['spaces', 'props', 'furniture', 'avatar'].map((tag) => (
                <FilterButton
                  key={tag}
                  tag={tag}
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes(tag)}
                />
              ))}
            </div>
            <div className='secondaryFilters grid-cols-4 place-items-center gap-1'>
              <br />
              {activeFilters.includes('avatar') && (
                <FilterButton
                  key='male'
                  tag='male'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('male')}
                />
              )}
              {activeFilters.includes('avatar') && (
                <FilterButton
                  key='female'
                  tag='female'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('female')}
                />
              )}
              {activeFilters.includes('avatar') && (
                <FilterButton
                  key='nonbinary'
                  tag='nonbinary'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('nonbinary')}
                />
              )}
              {activeFilters.includes('avatar') && (
                <FilterButton
                  key='kids'
                  tag='kids'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('kids')}
                />
              )}
              {activeFilters.includes('props') && (
                <FilterButton
                  key='screens'
                  tag='screens'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('screens')}
                />
              )}
              {activeFilters.includes('props') && (
                <FilterButton
                  key='plants'
                  tag='plants'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('plants')}
                />
              )}
              {activeFilters.includes('props') && (
                <FilterButton
                  key='helpers'
                  tag='helpers'
                  onClick={handleToggleFilter}
                  isActive={activeFilters.includes('helpers')}
                />
              )}
            </div>
          </div>

          <div>
            {prevPageUrl && (
              <button className='text-white bg-[#A100FF] p-2 mb-8' onClick={handleLoadPrevious}>
                Load Previous
              </button>
            )}
          </div>

          <div className='flex justify-center mb-44'>
            {filteredModels.length > 0 ? (
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mx-4 xl:grid-cols-5 gap-y-32 sm:gap-y-36 gap-x-4'>
                {filteredModels.map((model, index) => (
                  <div
                    key={`model.uid-${index}`}
                    className='flex flex-col justify-center aspect-w-1 aspect-h-1'
                  >
                    <div className='relative'>
                      <iframe
                        src={`${model.embedUrl}?camera=0&ui_infos=0&ui_watermark_link=0&ui_start=0&ui_color=a100ff&ui_watermark=0`}
                        title={model.name}
                        frameBorder='0'
                        allow='autoplay; fullscreen; vr'
                        allowFullScreen
                        className='w-full h-full'
                      />
                      <div
                        className='border border-gray-300 px-[0.6vw] xl:px-4 text-[0.8rem] md:text-[1.3vw] xl:text-base'
                        style={{ height: 'auto' }} // Adjust the height based on content
                      >
                        <span className='clickToPreview'>Click image to preview</span>
                        <span className='w-full relative px-6 py-3'>
                          <h3 className='w-full relative text-gray text-left min-h-[3rem]'>
                            {model.name}
                          </h3>
                          <a
                            className='text-white text-left flex flex-row md:mt-2 bg-[#A100FF] w-fit px-2 pb-1'
                            href='#_'
                            onClick={() => sendEmail(model)}
                          >
                            Request info
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className='text-white'>Loading models...</p>
            )}
          </div>
          <div>
            {nextPageUrl && (
              <button className='text-white bg-[#A100FF] p-2 mb-8' onClick={handleLoadMore}>
                Load More
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
