import React from 'react'

type TitleProps = {
  title: string
  subtitle: string
}

function Title({ title, subtitle }: TitleProps) {
  return (
    <div className='absolute top-1/2 left-[2rem] transform text-white'>
      <h1 className='font-bold text-white text-[3.5vw]'>{title}</h1>
      <p className='text-left'>{subtitle}</p>
    </div>
  )
}

export default Title
