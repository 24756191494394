import React from 'react'
import ColumnContent from './ColumnContent'
import MultiDeviceAccess from '../../../assets/images/MultiDeviceAccessDark.png'
import Assets from '../../../assets/images/1000AssetsDark.png'
import NoDownloads from '../../../assets/images/NoDownloadsDark.png'

type VisionProps = {
  ID: string
}

export const Vision = ({ ID }: VisionProps) => {
  return (
    <div className='text-white items-center justify-center' id={ID}>
      <div className='flex flex-col sm:px-16 xl:px-32 lg:pb-32 p-6 items-left'>
        <div className='flex mt-6'>
          <div className='grid grid-cols-1 smmd:grid-cols-3 gap-4 md:gap-8 lg:gap-16 2xl:gap-32 w-full justify-items-center'>
            <ColumnContent
              image={MultiDeviceAccess}
              title={'Multi-device\naccess'}
              description='Virtual Show Floor gives you accessibility with top quality. Access your projects from most devices while enjoying a fantastic performance for your Metaverse adventures.'
            />
            <ColumnContent
              image={Assets}
              title={'1000+ assets\nready to use'}
              description='Save time and effort with our vast collection of digital assets. Virtual Show Floor makes your world-building easier so you can focus on creating powerful experiences.'
            />
            <ColumnContent
              image={NoDownloads}
              title={'No downloads\nno installations'}
              description='Virtual Show Floor is a browser-based digital world platform, so it’s just one login away. Jump in using your preferred web browser and your account credentials. Simple, right?'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
