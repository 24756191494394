import React from 'react'
import HomeSlider from './homeSlider'

type CatalogueProps = {
  ID: string
}

export const Catalogue = ({ ID }: CatalogueProps) => {
  return (
    <div
      className='bg-black items-center'
      id={ID}
      style={{
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='text-white flex flex-col py-6 px-4 smmd:px-16 xl:px-32 items-left'>
        <div className='lg:py-x-36 pt-10 sm:text-left items-left'>
          <h1 className=' text-[4.77vw] smmd:text-[2.77vw] font-big-title text-left'>
            Create immersive experiences
            <br />
            tailored to your brand
          </h1>
          <p className='leading-tight py-6 text-[3.5vw] sm:text-base xl:text-xl break-words'>
            Explore these demos and get inspired by all the possibilities Virtual Show Floor brings.
          </p>
        </div>
        <div className='carousel-container'>
          <HomeSlider />
        </div>
      </div>
    </div>
  )
}
